import { useEffect } from "react";
import UserImage from "src/assets/dist/img/user2-160x160.jpg";
import { Link } from "react-router-dom";
const Sidebar = ({ logo, logoTitle, avatar, userName, menus, history }) => {
    useEffect(() => {
        const sidebar_open = document.body.classList.contains("sidebar-open");
        if (sidebar_open) {
            document.body.classList.remove("sidebar-open");
            document.body.classList.add("sidebar-closed");
        }
    }, [history.location.pathname]);
    return (
        <aside className="main-sidebar elevation-4 sidebar-light-success">
            <Link to="#" className="brand-link">
                {logo ? (
                    <img
                        src={logo ? logo : ""}
                        alt={"Bidbox Logo"}
                        className="brand-image img-circle elevation-3"
                        style={{ opacity: 0.8 }}
                    />
                ) : (
                    ""
                )}
                {logoTitle ? (
                    <span className="brand-text font-weight-light">
                        {logoTitle ? logoTitle : ""}
                    </span>
                ) : (
                    ""
                )}
            </Link>

            <div className="sidebar">
                <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                    <div className="image">
                        <img
                            src={avatar ? avatar : UserImage}
                            className="img-circle elevation-2"
                            alt={avatar ? avatar : UserImage}
                        />
                    </div>
                    <div className="info">
                        <Link to="#" className="d-block">
                            {userName ? userName : "John due"}
                        </Link>
                    </div>
                </div>

                <nav className="mt-2">
                    <ul
                        className="nav nav-pills nav-sidebar flex-column"
                        data-widget="treeview"
                        role="menu"
                        data-accordion="false"
                    >
                        {menus.items &&
                            menus.items.map((e1, i1) => (
                                <li
                                    key={i1}
                                    className={
                                        e1.isActive && e1.isActive !== false
                                            ? "nav-item menu-is-opening menu-open"
                                            : e1.items.length > 0
                                            ? "nav-item menu-is-opening menu-open"
                                            : "nav-item"
                                    }
                                >
                                    <Link
                                        to={e1.link}
                                        className={
                                            e1.isActive && e1.isActive !== false
                                                ? "nav-link active"
                                                : "nav-link"
                                        }
                                    >
                                        {e1.icon && (
                                            <i
                                                className={`nav-icon ${
                                                    e1.icon ? e1.icon : ""
                                                }`}
                                            ></i>
                                        )}
                                        <p>
                                            {e1.label}
                                            {e1.items &&
                                                e1.items.length > 0 && (
                                                    <i className="right fas fa-angle-left"></i>
                                                )}
                                        </p>
                                    </Link>
                                    {e1.items && e1.items.length > 0 && (
                                        <ul className="nav nav-treeview">
                                            {e1.items.map((e2, i2) => (
                                                <li
                                                    key={i2}
                                                    className={
                                                        e2.isActive &&
                                                        e2.isActive !== false
                                                            ? "nav-item menu-is-opening menu-open"
                                                            : "nav-item"
                                                    }
                                                >
                                                    <Link
                                                        to={e2.link}
                                                        className={
                                                            e2.isActive &&
                                                            e2.isActive !==
                                                                false
                                                                ? "nav-link active"
                                                                : "nav-link"
                                                        }
                                                    >
                                                        {e2.icon && (
                                                            <i
                                                                className={
                                                                    e2.icon
                                                                }
                                                            ></i>
                                                        )}
                                                        <p>{e2.label}</p>

                                                        {e2.items &&
                                                            e2.items.length >
                                                                0 && (
                                                                <i className="right fas fa-angle-left"></i>
                                                            )}
                                                    </Link>
                                                    {e2.items &&
                                                        e2.items.length > 0 && (
                                                            <ul className="nav nav-treeview">
                                                                {e2.items.map(
                                                                    (
                                                                        e3,
                                                                        i3
                                                                    ) => (
                                                                        <li
                                                                            key={
                                                                                i3
                                                                            }
                                                                            className={
                                                                                e3.isActive &&
                                                                                e3.isActive !==
                                                                                    false
                                                                                    ? "nav-item menu-is-opening menu-open"
                                                                                    : "nav-item"
                                                                            }
                                                                        >
                                                                            <Link
                                                                                to={
                                                                                    e3.link
                                                                                }
                                                                                className={
                                                                                    e3.isActive &&
                                                                                    e3.isActive ===
                                                                                        true
                                                                                        ? "nav-link active"
                                                                                        : ""
                                                                                }
                                                                            >
                                                                                {e3.icon && (
                                                                                    <i
                                                                                        className={
                                                                                            e3.icon
                                                                                        }
                                                                                    ></i>
                                                                                )}
                                                                                <p>
                                                                                    {
                                                                                        e3.label
                                                                                    }
                                                                                </p>

                                                                                {e3.items &&
                                                                                    e3
                                                                                        .items
                                                                                        .length >
                                                                                        0 && (
                                                                                        <i className="right fas fa-angle-left"></i>
                                                                                    )}
                                                                            </Link>
                                                                        </li>
                                                                    )
                                                                )}
                                                            </ul>
                                                        )}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </li>
                            ))}
                    </ul>
                </nav>
            </div>
        </aside>
    );
};
export default Sidebar;
