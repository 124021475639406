// import assets css
import "src/assets/dist/css/fonts.css";
import "src/assets/plugins/fontawesome-free/css/all.min.css";
import "src/assets/plugins/icheck-bootstrap/icheck-bootstrap.min.css";
import "src/assets/dist/css/adminlte.css";

const AuthLayout = (props) => {
    return (
        <div className="hold-transition login-page">
            <div className="login-box">{props.children}</div>
        </div>
    );
};
export default AuthLayout;
