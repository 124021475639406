export const GET_DEPARTMENT = "GET_DEPARTMENT";
export const GET_DEPARTMENT_SUCCESS = "GET_DEPARTMENT_SUCCESS";
export const GET_DEPARTMENT_ERROR = "GET_DEPARTMENT_ERROR";
export const DEPARTMENT_INIT = "TICKET_INIT";
export const UPDATE_DEPARTMENT = "UPDATE_DEPARTMENT";
export const UPDATE_DEPARTMENT_SUCCESS = "UPDATE_DEPARTMENT_SUCCESS";
export const UPDATE_DEPARTMENT_ERROR = "UPDATE_DEPARTMENT_ERROR";
export const CREATE_DEPARTMENT = "CREATE_DEPARTMENT";
export const CREATE_DEPARTMENT_SUCCESS = "CREATE_DEPARTMENT_SUCCESS";
export const CREATE_DEPARTMENT_ERROR = "CREATE_DEPARTMENT_ERROR";
export const DELETE_DEPARTMENT = "DELETE_DEPARTMENT";
export const DELETE_DEPARTMENT_SUCCESS = "DELETE_DEPARTMENT_SUCCESS";
export const DELETE_DEPARTMENT_ERROR = "DELETE_DEPARTMENT_ERROR";
