import React from "react";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import { authRoutes, adminRoutes, nonAuthRoutes } from "./routes/allRoutes";

import Authmiddleware from "./routes/middleware/Authmiddleware";

function App() {
    return (
        <Router>
            <Switch>
                {authRoutes.map((route, idx) => (
                    <Authmiddleware
                        key={idx}
                        path={route.path}
                        component={route.component}
                        isAuthProtected={false}
                        isAuthRoutes={true}
                        layout={route.layout}
                    />
                ))}
                {adminRoutes.map((route, idx) => (
                    <Authmiddleware
                        key={idx}
                        path={route.path}
                        component={route.component}
                        isAuthProtected={true}
                        isAuthRoutes={false}
                        layout={route.layout}
                    />
                ))}

                {nonAuthRoutes.map((route, index) => (
                    <Authmiddleware
                        key={index}
                        path={route.path}
                        component={route.component}
                        isAuthProtected={false}
                        isAuthRoutes={false}
                        layout={route.layout}
                    />
                ))}
                <Redirect strict from="/" to="/auth/signin" />
            </Switch>
        </Router>
    );
}

export default App;
