import { takeLatest, put, call } from "redux-saga/effects";

import {
    GET_TICKET,
    GET_TICKET_DETAIL,
    REPLY_TICKET,
    UPDATE_TICKET,
    DELETE_TICKET,
    DELETE_TICKET_ITEM,
} from "./actionTypes";
import {
    getTicketSuccess,
    getTicketError,
    getTicketDetailSuccess,
    getTicketDetailError,
    replyTicketSuccess,
    replyTicketError,
    deleteTicketSuccess,
    deleteTicketError,
    deleteItemTicketSuccess,
    deleteItemTicketError,
    updateTicketSuccess,
    updateTicketError,
} from "./actions";

import {
    getTicketHistory as apiGetTicket,
    replyTicket as apiReplyTicket,
    updateStatusTicket as apiUpdateTicket,
    deleteTicket as apiDeleteTicket,
    deleteItemTicket as apiDeleteItemTicket,
} from "src/api";
function* getTicket({ payload }) {
    try {
        const response = yield call(apiGetTicket, payload);
        const data = response.data;
        yield put(getTicketSuccess(data));
    } catch (error) {
        let message = String(error);
        try {
            message = error.response.data.message;
        } catch (q) {}
        yield put(getTicketError(message));
    }
}

function* getTicketDetail({ payload }) {
    try {
        const response = yield call(apiGetTicket, payload);
        const data = response.data;
        yield put(getTicketDetailSuccess(data));
    } catch (error) {
        let message = String(error);
        try {
            message = error.response.data.message;
        } catch (q) {}
        yield put(getTicketDetailError(message));
    }
}

function* replyTicket({ payload }) {
    try {
        const response = yield call(apiReplyTicket, payload);
        const data = response.data;
        yield put(replyTicketSuccess(data));
    } catch (error) {
        let message = String(error);
        try {
            message = error.response.data.message;
        } catch (q) {}
        yield put(replyTicketError(message));
    }
}

function* updateTicket({ payload }) {
    try {
        yield call(apiUpdateTicket, payload);
        yield put(updateTicketSuccess("successful ticket status update"));
    } catch (error) {
        let message = String(error);
        try {
            message = error.response.data.message;
        } catch (q) {}
        yield put(updateTicketError(message));
    }
}

function* deleteTicket({ id, payload }) {
    try {
        const response = yield call(apiDeleteTicket, id, payload);
        const data = response.data;
        yield put(deleteTicketSuccess(data));
    } catch (error) {
        let message = String(error);
        try {
            message = error.response.data.message;
        } catch (q) {}
        yield put(deleteTicketError(message));
    }
}

function* deleteItemTicket({ id, payload }) {
    try {
        const response = yield call(apiDeleteItemTicket, id, payload);
        const data = response.data;
        yield put(deleteItemTicketSuccess(data));
    } catch (error) {
        let message = String(error);
        try {
            message = error.response.data.message;
        } catch (q) {}
        yield put(deleteItemTicketError(message));
    }
}
function* authSaga() {
    yield takeLatest(GET_TICKET, getTicket);
    yield takeLatest(GET_TICKET_DETAIL, getTicketDetail);
    yield takeLatest(REPLY_TICKET, replyTicket);
    yield takeLatest(UPDATE_TICKET, updateTicket);
    yield takeLatest(DELETE_TICKET, deleteTicket);
    yield takeLatest(DELETE_TICKET_ITEM, deleteItemTicket);
}

export default authSaga;
