import { takeLatest, takeEvery, put, call } from "redux-saga/effects";

import { SIGNIN, LOGOUT } from "./actionTypes";
import { loginSuccess, loginError } from "./actions";

import { loginAdmin as apiLogin } from "src/api";
function* loginAdmin({ payload: { user, history } }) {
    try {
        const response = yield call(apiLogin, user.email, user.password);
        const data = response.data;
        localStorage.setItem(
            "bidbox_support_ticket_authUser",
            JSON.stringify({
                ...data,
                menu: [],
            })
        );
        localStorage.setItem(
            "bidbox_master_support_menu",
            JSON.stringify({
                menu: [],
            })
        );

        yield put(loginSuccess(data));
        window.location.href = "/admin/dashboard";
    } catch (error) {
        let message = String(error);
        try {
            message = error.response.data.message;
        } catch (q) {}
        yield put(loginError(message));
    }
}
function* logoutUser() {
    try {
        localStorage.removeItem("bidbox_support_ticket_authUser");
        window.location.href = "/";
    } catch (error) {
        yield put(loginError(error));
    }
}
function* authSaga() {
    yield takeLatest(SIGNIN, loginAdmin);
    yield takeEvery(LOGOUT, logoutUser);
}

export default authSaga;
