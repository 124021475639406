import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { updateTicketCategory, getTicketCategory } from "src/store/actions";
import Select from "react-select";
import LogoBidbox from "src/assets/dist/img/logo.png";
const TicketCategoryEdit = () => {
    const [isLoading, setIsLoading] = useState(false);
    const pathname = window.location.pathname;
    const split_segment_url = pathname.split("/");
    const id_ticket_category = split_segment_url[split_segment_url.length - 1];
    const [alert, setAlert] = useState({
        show: false,
        type: "info",
        message: "",
    });
    const dispatch = useDispatch();
    const [dataStatus, setDataStatus] = useState([
        { value: true, label: "Active" },
        { value: false, label: "Inactive" },
    ]);
    const [categoryName, setCategoryName] = useState("");
    const [isActive, setIsActive] = useState(false);
    const ticketCategoryState = useSelector((state) => state.TicketCategory);
    const onChangeSelect = (val) => {
        if (val.value === true) setIsActive(true);
        if (val.value === false) setIsActive(false);
    };
    const submitUpdate = () => {
        dispatch(
            updateTicketCategory(id_ticket_category, {
                name: categoryName,
                is_active: isActive,
            })
        );
    };
    useEffect(() => {
        if (ticketCategoryState.items.length === 1) {
            setCategoryName(ticketCategoryState.items[0].name);
            setIsActive(ticketCategoryState.items[0].is_active);
        }
    }, [ticketCategoryState.items]);
    useEffect(() => {
        if (ticketCategoryState.successMessage !== null) {
            setAlert({
                type: "info",
                message: ticketCategoryState.successMessage,
                show: true,
            });
        }

        if (ticketCategoryState.errorMessage !== null) {
            setAlert({
                type: "danger",
                message: ticketCategoryState.errorMessage,
                show: true,
            });
        }
    }, [ticketCategoryState.successMessage, ticketCategoryState.errorMessage]);
    useEffect(() => {
        dispatch(
            getTicketCategory({
                id: id_ticket_category,
                page: 1,
                per_page: 10,
            })
        );
    }, []);
    useEffect(() => {
        setIsLoading(ticketCategoryState.loading);
    }, [ticketCategoryState.loading]);
    return (
        <div>
            <div
                className={`modal fade ${isLoading ? "show" : ""}`}
                id="modal-default"
                aria-modal="true"
                style={{
                    display: `${isLoading ? "block" : "none"}`,
                    backgroundColor: "rgba(0,0,0,0.5)",
                }}
                role="dialog"
            >
                <div className="modal-dialog modal-sm" style={{ width: 150 }}>
                    <div className="modal-content">
                        <div
                            className="modal-body"
                            style={{ textAlign: "center" }}
                        >
                            <img
                                src={LogoBidbox}
                                alt="LogoBidbox"
                                width={50}
                                style={{}}
                            />
                            <h5>Loading...</h5>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Edit Ticket Category</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item">
                                    <Link to="#">Home</Link>
                                </li>
                                <li className="breadcrumb-item active">
                                    Edit Ticket Category
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            {alert.show && (
                                <div
                                    className={`alert alert-${alert.type} alert-dismissible`}
                                >
                                    <button
                                        type="button"
                                        onClick={() => {
                                            setAlert({
                                                ...alert,
                                                show: false,
                                            });
                                        }}
                                        className="close"
                                        data-dismiss="alert"
                                        aria-hidden="true"
                                    >
                                        ×
                                    </button>
                                    {alert.message}
                                </div>
                            )}
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">
                                        Form Ticket Category
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <div className="form-group">
                                        <label>Category Name</label>
                                        <input
                                            type="text"
                                            value={categoryName}
                                            placeholder="Category Name"
                                            onChange={(e) =>
                                                setCategoryName(e.target.value)
                                            }
                                            className="form-control"
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label>Status</label>
                                        <Select
                                            options={dataStatus}
                                            value={dataStatus.filter(
                                                (option) =>
                                                    option.value === isActive
                                            )}
                                            onChange={(value) =>
                                                onChangeSelect(value)
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <button
                                        type="button"
                                        onClick={submitUpdate}
                                        className="btn btn-primary"
                                    >
                                        Update
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};
export default TicketCategoryEdit;
