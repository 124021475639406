import {
    SIGNIN,
    SIGNIN_SUCCESS,
    SIGNIN_ERROR,
    LOGOUT,
    LOGOUT_SUCCESS,
} from "./actionTypes";

export const login = (user, history) => {
    return {
        type: SIGNIN,
        payload: { user, history },
    };
};

export const logoutUser = (history) => {
    return {
        type: LOGOUT,
        payload: { history },
    };
};

export const loginSuccess = (message) => {
    return {
        type: SIGNIN_SUCCESS,
        payload: message,
    };
};
export const loginError = (message) => {
    return {
        type: SIGNIN_ERROR,
        payload: message,
    };
};

export const logoutSuccess = (message) => {
    return {
        type: LOGOUT_SUCCESS,
        payload: message,
    };
};
