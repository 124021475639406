import {
    GET_USERS,
    GET_USERS_SUCCESS,
    GET_USERS_ERROR,
    UPDATE_USERS,
    UPDATE_USERS_SUCCESS,
    UPDATE_USERS_ERROR,
    DELETE_USERS,
    DELETE_USERS_SUCCESS,
    DELETE_USERS_ERROR,
    CREATE_USERS,
    CREATE_USERS_SUCCESS,
    CREATE_USERS_ERROR,
    USERS_INIT,
} from "./actionTypes";

const initialState = {
    loading: false,
    detail: null,
    items: [],
    id: null,
    errorMessage: null,
    successMessage: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case USERS_INIT:
            state = {
                ...state,
                loading: false,
                successMessage: null,
                errorMessage: null,
            };
            break;
        case GET_USERS:
            state = {
                ...state,
                ...action.payload,
                loading: true,
                successMessage: null,
                errorMessage: null,
            };
            break;
        case GET_USERS_SUCCESS:
            state = {
                ...state,
                ...action.payload,
                loading: false,
                successMessage: null,
                errorMessage: null,
            };
            break;
        case GET_USERS_ERROR:
            state = {
                ...state,
                loading: false,
                successMessage: null,
                errorMessage: action.payload,
            };
            break;

        case UPDATE_USERS:
            state = {
                ...state,
                ...action.payload,
                loading: true,
                successMessage: null,
                errorMessage: null,
            };
            break;
        case UPDATE_USERS_SUCCESS:
            state = {
                ...state,
                loading: false,
                detail: null,
                successMessage: action.payload,
                errorMessage: null,
            };
            break;
        case UPDATE_USERS_ERROR:
            state = {
                ...state,
                loading: false,
                successMessage: null,
                errorMessage: action.payload,
            };
            break;

        case DELETE_USERS:
            state = {
                ...state,
                ...action.payload,
                loading: true,
                successMessage: null,
                errorMessage: null,
            };
            break;
        case DELETE_USERS_SUCCESS:
            state = {
                ...state,
                loading: false,
                successMessage: action.payload,
                errorMessage: null,
            };
            break;
        case DELETE_USERS_ERROR:
            state = {
                ...state,
                loading: false,
                successMessage: null,
                errorMessage: action.payload,
            };
            break;

        case CREATE_USERS:
            state = {
                ...state,
                ...action.payload,
                loading: true,
                successMessage: null,
                errorMessage: null,
            };
            break;
        case CREATE_USERS_SUCCESS:
            state = {
                ...state,
                loading: false,
                detail: null,
                successMessage: action.payload,
                errorMessage: null,
            };
            break;
        case CREATE_USERS_ERROR:
            state = {
                ...state,
                loading: false,
                successMessage: null,
                errorMessage: action.payload,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default reducer;
