import {
    GET_TICKET_CATEGORY,
    GET_TICKET_CATEGORY_SUCCESS,
    GET_TICKET_CATEGORY_ERROR,
    CREATE_TICKET_CATEGORY,
    CREATE_TICKET_CATEGORY_SUCCESS,
    CREATE_TICKET_CATEGORY_ERROR,
    UPDATE_TICKET_CATEGORY,
    UPDATE_TICKET_CATEGORY_SUCCESS,
    UPDATE_TICKET_CATEGORY_ERROR,
    DELETE_TICKET_CATEGORY,
    DELETE_TICKET_CATEGORY_SUCCESS,
    DELETE_TICKET_CATEGORY_ERROR,
    TICKET_CATEGORY_INIT,
} from "./actionTypes";

export const getTicketCategory = (payload) => {
    return {
        type: GET_TICKET_CATEGORY,
        payload: payload,
    };
};

export const getTicketCategorySuccess = (payload) => {
    return {
        type: GET_TICKET_CATEGORY_SUCCESS,
        payload: payload,
    };
};
export const getTicketCategoryError = (message) => {
    return {
        type: GET_TICKET_CATEGORY_ERROR,
        payload: message,
    };
};

export const ticketCategoryInit = () => {
    return {
        type: TICKET_CATEGORY_INIT,
    };
};

export const updateTicketCategory = (id, payload) => {
    return {
        type: UPDATE_TICKET_CATEGORY,
        payload: payload,
        id: id,
    };
};

export const updateTicketCategorySuccess = (message) => {
    return {
        type: UPDATE_TICKET_CATEGORY_SUCCESS,
        payload: message,
    };
};
export const updateTicketCategoryError = (message) => {
    return {
        type: UPDATE_TICKET_CATEGORY_ERROR,
        payload: message,
    };
};

export const deleteTicketCategory = (id) => {
    return {
        type: DELETE_TICKET_CATEGORY,
        payload: id,
    };
};

export const deleteTicketCategorySuccess = (message) => {
    return {
        type: DELETE_TICKET_CATEGORY_SUCCESS,
        payload: message,
    };
};
export const deleteTicketCategoryError = (message) => {
    return {
        type: DELETE_TICKET_CATEGORY_ERROR,
        payload: message,
    };
};

export const createTicketCategory = (payload) => {
    return {
        type: CREATE_TICKET_CATEGORY,
        payload: payload,
    };
};

export const createTicketCategorySuccess = (message) => {
    return {
        type: CREATE_TICKET_CATEGORY_SUCCESS,
        payload: message,
    };
};
export const createTicketCategoryError = (message) => {
    return {
        type: CREATE_TICKET_CATEGORY_ERROR,
        payload: message,
    };
};
