import axios from "axios";
import endpoint from "./endpoint";
import { configNotStrict } from "./config";
// login Admin
export const loginAdmin = async (email, password) => {
    let data = JSON.stringify({
        email: email,
        password: password,
    });
    let config = configNotStrict({
        method: "post",
        url: endpoint.API_AUTH_LOGIN,
        headers: {
            "Content-Type": "application/json",
        },
        data: data,
    });
    let res = await axios(config);
    return res;
};
// refresh token
export const refreshToken = async (token) => {
    const res = configNotStrict({
        method: "post",
        url: endpoint.API_REFRESH_TOKEN,
        headers: {
            "Content-Type": "application/json",
        },
        data: { token: token },
    });
    return res;
};
