const BASE_URL = process.env.REACT_APP_API_GATEWAY;

const Apis = {
    API_AUTH_LOGIN: `${BASE_URL}/auth/login`,

    API_REFRESH_TOKEN: `${BASE_URL}/refresh-token`,
    API_GET_TICKET: `${BASE_URL}/strict/admin/ticket/history`,
    API_REPLY_TICKET: `${BASE_URL}/strict/admin/ticket/reply`,
    API_UPDATE_STATUS_TICKET: `${BASE_URL}/strict/admin/ticket`,
    API_DELETE_TICKET: `${BASE_URL}/strict/admin/ticket`,
    API_DELETE_ITEM_TICKET: `${BASE_URL}/strict/admin/ticket`,
    API_CREATE_TICKET_CATEGORY: `${BASE_URL}/strict/master/ticket-category`,
    API_UPDATE_TICKET_CATEGORY: `${BASE_URL}/strict/master/ticket-category`,
    API_DELETE_TICKET_CATEGORY: `${BASE_URL}/strict/master/ticket-category`,
    API_GET_TICKET_CATEGORY: `${BASE_URL}/strict/master/ticket-category`,
    API_GET_DEPARTMENT: `${BASE_URL}/strict/master/department`,
    API_CREATE_DEPARTMENT: `${BASE_URL}/strict/master/department`,
    API_UPDATE_DEPARTMENT: `${BASE_URL}/strict/master/department`,
    API_DELETE_DEPARTMENT: `${BASE_URL}/strict/master/department`,
    API_GET_USER: `${BASE_URL}/strict/user`,
    API_CREATE_USER: `${BASE_URL}/strict/user`,
    API_UPDATE_USER: `${BASE_URL}/strict/user`,
    API_DELETE_USER: `${BASE_URL}/strict/user`,
};
export default Apis;
