import { takeLatest, put, call } from "redux-saga/effects";

import {
    GET_DEPARTMENT,
    CREATE_DEPARTMENT,
    UPDATE_DEPARTMENT,
    DELETE_DEPARTMENT,
} from "./actionTypes";
import {
    getDepartmentSuccess,
    getDepartmentError,
    createDepartmentSuccess,
    createDepartmentError,
    deleteDepartmentSuccess,
    deleteDepartmentError,
    updateDepartmentSuccess,
    updateDepartmentError,
} from "./actions";

import {
    getDepartment as apiGetDepartment,
    createDepartment as apiCreateDepartment,
    updateDepartment as apiUpdateDepartment,
    deleteDepartment as apiDeleteDepartment,
} from "src/api";
function* getDepartment({ payload }) {
    try {
        const response = yield call(apiGetDepartment, payload);
        const data = response.data;
        yield put(getDepartmentSuccess(data));
    } catch (error) {
        let message = String(error);
        try {
            message = error.response.data.message;
        } catch (q) {}
        yield put(getDepartmentError(message));
    }
}

function* createDepartment({ payload }) {
    try {
        yield call(apiCreateDepartment, payload);
        yield put(createDepartmentSuccess("successful department created"));
    } catch (error) {
        let message = String(error);
        try {
            message = error.response.data.message;
        } catch (q) {}
        yield put(createDepartmentError(message));
    }
}

function* updateDepartment({ id, payload }) {
    try {
        yield call(apiUpdateDepartment, id, payload);
        yield put(updateDepartmentSuccess("successful department update"));
    } catch (error) {
        let message = String(error);
        try {
            message = error.response.data.message;
        } catch (q) {}
        yield put(updateDepartmentError(message));
    }
}

function* deleteDepartment({ payload }) {
    try {
        yield call(apiDeleteDepartment, payload);
        yield put(deleteDepartmentSuccess("successful department deleted"));
    } catch (error) {
        let message = String(error);
        try {
            message = error.response.data.message;
        } catch (q) {}
        yield put(deleteDepartmentError(message));
    }
}

function* authSaga() {
    yield takeLatest(GET_DEPARTMENT, getDepartment);
    yield takeLatest(CREATE_DEPARTMENT, createDepartment);
    yield takeLatest(UPDATE_DEPARTMENT, updateDepartment);
    yield takeLatest(DELETE_DEPARTMENT, deleteDepartment);
}

export default authSaga;
