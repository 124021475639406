import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { createUsers } from "src/store/actions";
import Table from "src/components/table/Table";
import Select from "react-select";
import LogoBidbox from "src/assets/dist/img/logo.png";
const UserCreate = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [alert, setAlert] = useState({
        show: false,
        type: "info",
        message: "",
    });
    const dispatch = useDispatch();
    const [dataStatus, setDataStatus] = useState([
        { value: "active", label: "Active" },
        { value: "inactive", label: "Inactive" },
        { value: "suspend", label: "Suspend" },
    ]);

    const [dataRole, setDataRole] = useState([
        { value: "administrator", label: "Administrator" },
    ]);
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [status, setStatus] = useState("");
    const [password, setPassword] = useState("");
    const [role, setRole] = useState("");
    const usersState = useSelector((state) => state.Users);
    const onChangeSelectStatus = (val) => {
        setStatus(val.value);
    };
    const onChangeSelectRole = (val) => {
        setRole(val.value);
    };
    const submitCreate = () => {
        dispatch(
            createUsers({
                name: fullName,
                email: email,
                password: password,
                status: status,
                role: role,
            })
        );
    };
    useEffect(() => {
        if (usersState.successMessage !== null) {
            setAlert({
                type: "info",
                message: usersState.successMessage,
                show: true,
            });
            setFullName("");
            setEmail("");
            setPassword("");
        }

        if (usersState.errorMessage !== null) {
            setAlert({
                type: "danger",
                message: usersState.errorMessage,
                show: true,
            });
        }
    }, [usersState.successMessage, usersState.errorMessage]);
    useEffect(() => {
        setIsLoading(usersState.loading);
    }, [usersState.loading]);
    return (
        <div>
            <div
                className={`modal fade ${isLoading ? "show" : ""}`}
                id="modal-default"
                aria-modal="true"
                style={{
                    display: `${isLoading ? "block" : "none"}`,
                    backgroundColor: "rgba(0,0,0,0.5)",
                }}
                role="dialog"
            >
                <div className="modal-dialog modal-sm" style={{ width: 150 }}>
                    <div className="modal-content">
                        <div
                            className="modal-body"
                            style={{ textAlign: "center" }}
                        >
                            <img
                                src={LogoBidbox}
                                alt="LogoBidbox"
                                width={50}
                                style={{}}
                            />
                            <h5>Loading...</h5>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Create User</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item">
                                    <Link to="#">Home</Link>
                                </li>
                                <li className="breadcrumb-item active">
                                    Create User
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            {alert.show && (
                                <div
                                    className={`alert alert-${alert.type} alert-dismissible`}
                                >
                                    <button
                                        type="button"
                                        onClick={() => {
                                            setAlert({
                                                ...alert,
                                                show: false,
                                            });
                                        }}
                                        className="close"
                                        data-dismiss="alert"
                                        aria-hidden="true"
                                    >
                                        ×
                                    </button>
                                    {alert.message}
                                </div>
                            )}
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">Form User</h3>
                                </div>
                                <div className="card-body">
                                    <div className="form-group">
                                        <label>Full Name</label>
                                        <input
                                            type="text"
                                            placeholder="Full Name"
                                            value={fullName}
                                            onChange={(e) =>
                                                setFullName(e.target.value)
                                            }
                                            className="form-control"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Email</label>
                                        <input
                                            type="email"
                                            placeholder="Email"
                                            value={email}
                                            onChange={(e) =>
                                                setEmail(e.target.value)
                                            }
                                            className="form-control"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Password</label>
                                        <input
                                            type="password"
                                            placeholder="Password"
                                            value={password}
                                            onChange={(e) =>
                                                setPassword(e.target.value)
                                            }
                                            className="form-control"
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label>Status</label>
                                        <Select
                                            options={dataStatus}
                                            onChange={(value) =>
                                                onChangeSelectStatus(value)
                                            }
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label>Role</label>
                                        <Select
                                            options={dataRole}
                                            onChange={(value) =>
                                                onChangeSelectRole(value)
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <button
                                        type="button"
                                        onClick={submitCreate}
                                        className="btn btn-primary"
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};
export default UserCreate;
