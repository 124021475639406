import {
    GET_USERS,
    GET_USERS_SUCCESS,
    GET_USERS_ERROR,
    CREATE_USERS,
    CREATE_USERS_SUCCESS,
    CREATE_USERS_ERROR,
    UPDATE_USERS,
    UPDATE_USERS_SUCCESS,
    UPDATE_USERS_ERROR,
    DELETE_USERS,
    DELETE_USERS_SUCCESS,
    DELETE_USERS_ERROR,
    USERS_INIT,
} from "./actionTypes";

export const getUsers = (payload) => {
    return {
        type: GET_USERS,
        payload: payload,
    };
};

export const getUsersSuccess = (payload) => {
    return {
        type: GET_USERS_SUCCESS,
        payload: payload,
    };
};
export const getUsersError = (message) => {
    return {
        type: GET_USERS_ERROR,
        payload: message,
    };
};

export const UsersInit = () => {
    return {
        type: USERS_INIT,
    };
};

export const updateUsers = (id, payload) => {
    return {
        type: UPDATE_USERS,
        id: id,
        payload: payload,
    };
};

export const updateUsersSuccess = (message) => {
    return {
        type: UPDATE_USERS_SUCCESS,
        payload: message,
    };
};
export const updateUsersError = (message) => {
    return {
        type: UPDATE_USERS_ERROR,
        payload: message,
    };
};

export const deleteUsers = (payload) => {
    return {
        type: DELETE_USERS,
        payload: payload,
    };
};

export const deleteUsersSuccess = (message) => {
    return {
        type: DELETE_USERS_SUCCESS,
        payload: message,
    };
};
export const deleteUsersError = (message) => {
    return {
        type: DELETE_USERS_ERROR,
        payload: message,
    };
};

export const createUsers = (payload) => {
    return {
        type: CREATE_USERS,
        payload: payload,
    };
};

export const createUsersSuccess = (message) => {
    return {
        type: CREATE_USERS_SUCCESS,
        payload: message,
    };
};
export const createUsersError = (message) => {
    return {
        type: CREATE_USERS_ERROR,
        payload: message,
    };
};
