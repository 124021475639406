import htmlParse from "html-react-parser";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

import { debounce } from "src/utils/helpers";
/* 
onLoad ({
    sort,search,       
})
*/
const Table = ({
    headers,
    data,
    searching,
    paging,
    title,
    onLoad,
    colHeader,
    footers,
}) => {
    const [pagination, setPagination] = useState({
        previous: false,
        next: false,
        items: [1, 2],
    });
    const [search, setSearch] = useState("");
    const gotoPage = (page) => {
        onLoad({
            page: page,
            search: search,
            limit: data.limit,
        });
    };
    const nextPage = () => {
        if (pagination.next === false) return;
        onLoad({
            page: data.page + 1,
            search: search,
            limit: data.limit,
        });
    };
    const previousPage = () => {
        if (pagination.previous === false) return;
        onLoad({
            page: data.page - 1,
            search: search,
            limit: data.limit,
        });
    };
    useEffect(() => {
        let btnNext = false;
        let btnPrevious = false;
        let itemsPagination = [];
        if (data.totalItems > 0) {
            if (data.totalItems > data.limit * data.page) {
                btnNext = true;
            } else {
                btnNext = false;
            }
            if (data.page > 1) {
                btnPrevious = true;
            } else {
                btnPrevious = false;
            }
            let totalPage = Math.ceil(data.totalItems / data.limit);
            if (totalPage <= 4) {
                for (let index = 0; index < totalPage; index++) {
                    itemsPagination.push(index + 1);
                }
            } else {
                let pos = 0;
                let start_page = 1;
                if (data.page - 3 > 0) {
                    start_page = data.page - 3;
                }
                for (let index = start_page - 1; index < totalPage; index++) {
                    if (pos < 4) {
                        itemsPagination.push(index + 1);
                        pos++;
                    } else if (index + 3 >= totalPage) {
                        itemsPagination.push(index + 1);
                    }
                }
            }
        }

        setPagination({
            previous: btnPrevious,
            next: btnNext,
            items: itemsPagination,
        });
    }, [data]);
    useEffect(() => {
        onLoad({ page: data.page, search: search, limit: data.limit });
    }, []);
    const onSearch = debounce((e) => {
        setSearch(e.target.value);
        doSearch();
    }, 500);
    const doSearch = () => {
        onLoad({ page: data.page, search: search, limit: data.limit });
    };
    return (
        <div>
            <div className="card">
                <div className="card-header">
                    <h3 className="card-title">{title ? title : ""}</h3>
                    {searching && (
                        <div className="card-tools">
                            <div
                                className="input-group input-group-sm"
                                style={{ width: 150 }}
                            >
                                <input
                                    type="text"
                                    name="table_search"
                                    className="form-control float-right"
                                    placeholder="Search"
                                    onChange={onSearch}
                                />

                                <div className="input-group-append">
                                    <button
                                        type="button"
                                        onClick={doSearch}
                                        className="btn btn-default"
                                    >
                                        <i className="fas fa-search"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                {headers && <div className="card-header">{headers}</div>}

                <div className="card-body table-responsive p-0">
                    <table className="table table-hover text-nowrap">
                        <thead>
                            <tr>
                                {colHeader.map((k, v) => (
                                    <th style={data.styleTd[v]} key={v}>
                                        {k}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {data.items.length > 0 &&
                                data.items.map((k, v) => (
                                    <tr key={v}>
                                        {k.map((k2, v2) => (
                                            <td key={v2}>{k2}</td>
                                        ))}
                                    </tr>
                                ))}
                            {data.items.length <= 0 && (
                                <tr>
                                    <td colSpan={colHeader.length}>
                                        {data.totalFiltered > 0 &&
                                            "No matching records found"}
                                        {data.totalFiltered <= 0 &&
                                            "No data available in table"}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                {paging === true && (
                    <div className="card-header">
                        <span>
                            Showing{" "}
                            {data.items.length > 0
                                ? data.page * data.limit - data.limit + 1
                                : 0}{" "}
                            to{" "}
                            {data.items.length > 0
                                ? data.items.length +
                                  (data.page - 1) * data.limit
                                : 0}{" "}
                            of {data.items.length > 0 ? data.totalItems : 0}{" "}
                            entries
                            {data.totalItems !== data.totalFiltered
                                ? `(filtered from ${data.totalFiltered} total entries)`
                                : ""}
                        </span>
                        <div className="card-tools">
                            <ul className="pagination">
                                <li
                                    className={`paginate_button page-item previous ${
                                        pagination.previous ? "" : "disabled"
                                    }`}
                                >
                                    <Link
                                        to="#"
                                        onClick={previousPage}
                                        className="page-link"
                                    >
                                        Previous
                                    </Link>
                                </li>
                                {pagination.items.map((k, v) => (
                                    <li
                                        key={v}
                                        className={`paginate_button page-item ${
                                            k === data.page ? "active" : ""
                                        }`}
                                    >
                                        <Link
                                            to="#"
                                            onClick={() => {
                                                return gotoPage(k);
                                            }}
                                            className="page-link"
                                        >
                                            {k}
                                        </Link>
                                    </li>
                                ))}
                                <li
                                    className={`paginate_button page-item next ${
                                        pagination.next ? "" : "disabled"
                                    }`}
                                >
                                    <Link
                                        to="#"
                                        onClick={nextPage}
                                        className="page-link"
                                    >
                                        Next
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                )}
                {footers && <div className="card-footer">{footers}</div>}
            </div>
        </div>
    );
};
export default Table;
