import dataCoin from "src/assets/coin";
export const parseJwt = (token) => {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split("")
            .map(function (c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
    );

    return JSON.parse(jsonPayload);
};
export const capitalizeFirstLetter = (string) => {
    string = string.toLowerCase();
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const toFixedIfNecessary = (value, dp) => {
    return +parseFloat(value).toFixed(dp);
};
export const debounce = (f, ms) => {
    let timer = null;

    return function (...args) {
        const onComplete = () => {
            f.apply(this, args);
            timer = null;
        };

        if (timer) {
            clearTimeout(timer);
        }

        timer = setTimeout(onComplete, ms);
    };
};

export const searchIcon = async (base_asset) => {
    let image = "";
    await dataCoin
        .filter((key) => key.symbol === base_asset)
        .forEach((el) => {
            let id = el.id;
            if (el.symbol === "SOL") id = 5426;
            image = `https://s2.coinmarketcap.com/static/img/coins/64x64/${id}.png`;
        });
    return image;
};
