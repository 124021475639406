import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getTicketCategory, deleteTicketCategory } from "src/store/actions";
import Table from "src/components/table/Table";
import LogoBidbox from "src/assets/dist/img/logo.png";
const TicketCategoryList = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [alert, setAlert] = useState({
        show: false,
        type: "info",
        message: "",
    });

    const [modal, setModal] = useState({
        isVisible: false,
    });
    const dispatch = useDispatch();
    const ticketCategoryState = useSelector((state) => state.TicketCategory);
    const [ticketCategory, setTicketCategory] = useState({
        items: [],
        totalItems: 0,
        totalFiltered: 0,
        limit: 10,
        page: 1,
    });

    const loadTicketCategory = ({ page, search, limit }) => {
        dispatch(
            getTicketCategory({
                search: search,
                page: page,
                per_page: limit,
                sort: [["id", "ASC"]],
            })
        );
    };
    const submitModal = (id) => {
        dispatch(deleteTicketCategory(id));
        setModal({
            isVisible: false,
        });
    };
    const showModalDelete = (id, name) => {
        setModal({
            isVisible: true,
            id: id,
            name: name,
            title: "Delete",
        });
    };
    useEffect(() => {
        let data_category = [];
        ticketCategoryState.items.forEach((el) => {
            data_category.push([
                <div>
                    <Link
                        className="btn btn-xs btn-primary"
                        to={`/ticket-category/edit/${el.id}`}
                    >
                        <i className="fas fa-pencil"></i>
                    </Link>
                    &nbsp;
                    <button
                        className="btn btn-xs btn-danger"
                        onClick={() => showModalDelete(el.id, el.name)}
                    >
                        <i className="fas fa-trash"></i>
                    </button>
                </div>,
                el.id,
                el.name,
                el.is_active === true ? "Active" : "Inactive",
                new Date(el.created_at).toLocaleString("en-GB"),
            ]);
        });
        setTicketCategory({
            totalItems: ticketCategoryState.total_items,
            totalFiltered: ticketCategoryState.total_items,
            limit: ticketCategoryState.per_page,
            page: ticketCategoryState.current_page,
            items: data_category,
        });
    }, [ticketCategoryState.items]);

    useEffect(() => {
        if (ticketCategoryState.successMessage !== null) {
            setAlert({
                type: "info",
                message: ticketCategoryState.successMessage,
                show: true,
            });
            loadTicketCategory({
                page: 1,
                limit: 100,
                search: "",
            });
        }

        if (ticketCategoryState.errorMessage !== null) {
            setAlert({
                type: "danger",
                message: ticketCategoryState.errorMessage,
                show: true,
            });
        }
    }, [ticketCategoryState.successMessage, ticketCategoryState.errorMessage]);
    useEffect(() => {
        setIsLoading(ticketCategoryState.loading);
    }, [ticketCategoryState.loading]);
    return (
        <div>
            <div
                className={`modal fade ${isLoading ? "show" : ""}`}
                id="modal-default"
                aria-modal="true"
                style={{
                    display: `${isLoading ? "block" : "none"}`,
                    backgroundColor: "rgba(0,0,0,0.5)",
                }}
                role="dialog"
            >
                <div className="modal-dialog modal-sm" style={{ width: 150 }}>
                    <div className="modal-content">
                        <div
                            className="modal-body"
                            style={{ textAlign: "center" }}
                        >
                            <img
                                src={LogoBidbox}
                                alt="LogoBidbox"
                                width={50}
                                style={{}}
                            />
                            <h5>Loading...</h5>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Ticket Category</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item">
                                    <Link to="#">Home</Link>
                                </li>
                                <li className="breadcrumb-item active">
                                    Ticket Category
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            {alert.show && (
                                <div
                                    className={`alert alert-${alert.type} alert-dismissible`}
                                >
                                    <button
                                        type="button"
                                        onClick={() => {
                                            setAlert({
                                                ...alert,
                                                show: false,
                                            });
                                        }}
                                        className="close"
                                        data-dismiss="alert"
                                        aria-hidden="true"
                                    >
                                        ×
                                    </button>
                                    {alert.message}
                                </div>
                            )}
                            <Table
                                searching={false}
                                title="Ticket Category"
                                paging={true}
                                colHeader={[
                                    "#",
                                    "ID",
                                    "Category Name",
                                    "Status",
                                    "Created at",
                                ]}
                                data={{
                                    items: ticketCategory.items,
                                    totalFiltered: ticketCategory.totalFiltered,
                                    totalItems: ticketCategory.totalItems,
                                    page: ticketCategory.page,
                                    limit: ticketCategory.limit,
                                    styleTd: [{ width: 100 }, { width: 100 }],
                                }}
                                onLoad={loadTicketCategory}
                                footers={
                                    <Link
                                        className="btn btn-primary"
                                        to="/ticket-category/create"
                                    >
                                        Add
                                    </Link>
                                }
                            />
                        </div>
                    </div>
                </div>
                <div
                    className={`modal fade ${modal.isVisible ? "show" : ""}`}
                    id="modal-default"
                    aria-modal="true"
                    style={{
                        display: modal.isVisible ? "block" : "none",
                        backgroundColor: "rgba(0,0,0,0.5)",
                    }}
                    role="dialog"
                >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">{modal.title}</h4>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span
                                        aria-hidden="true"
                                        onClick={() =>
                                            setModal({
                                                ...modal,
                                                isVisible: false,
                                            })
                                        }
                                    >
                                        ×
                                    </span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td style={{ width: 150 }}>
                                                ID Ticket Category
                                            </td>
                                            <td>: {modal.id}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: 150 }}>
                                                Category Name
                                            </td>
                                            <td>: {modal.name}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="modal-footer justify-content-between">
                                <button
                                    type="button"
                                    className="btn btn-default"
                                    data-dismiss="modal"
                                    onClick={() =>
                                        setModal({
                                            ...modal,
                                            isVisible: false,
                                        })
                                    }
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => {
                                        submitModal(modal.id);
                                    }}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};
export default TicketCategoryList;
