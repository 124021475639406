import {
    GET_TICKET,
    GET_TICKET_SUCCESS,
    GET_TICKET_ERROR,
    GET_TICKET_DETAIL,
    GET_TICKET_DETAIL_SUCCESS,
    GET_TICKET_DETAIL_ERROR,
    REPLY_TICKET,
    REPLY_TICKET_SUCCESS,
    REPLY_TICKET_ERROR,
    UPDATE_TICKET,
    UPDATE_TICKET_SUCCESS,
    UPDATE_TICKET_ERROR,
    DELETE_TICKET,
    DELETE_TICKET_SUCCESS,
    DELETE_TICKET_ERROR,
    DELETE_TICKET_ITEM,
    DELETE_TICKET_ITEM_SUCCESS,
    DELETE_TICKET_ITEM_ERROR,
    TICKET_INIT,
} from "./actionTypes";

const initialState = {
    loading: false,
    detail: null,
    items: [],
    id: null,
    errorMessage: null,
    successMessage: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case TICKET_INIT:
            state = {
                ...state,
                loading: false,
                successMessage: null,
                errorMessage: null,
            };
            break;
        case GET_TICKET:
            state = {
                ...state,
                ...action.payload,
                loading: true,
                successMessage: null,
                errorMessage: null,
            };
            break;

        case GET_TICKET_DETAIL:
            state = {
                ...state,
                ...action.payload,
                loading: true,
                successMessage: null,
                errorMessage: null,
            };
            break;
        case GET_TICKET_SUCCESS:
            state = {
                ...state,
                ...action.payload,
                loading: false,
                successMessage: null,
                errorMessage: null,
            };
            break;

        case GET_TICKET_DETAIL_SUCCESS:
            state = {
                ...state,
                detail: action.payload,
                loading: false,
                successMessage: null,
                errorMessage: null,
            };
            break;

        case GET_TICKET_DETAIL_ERROR:
            state = {
                ...state,
                loading: false,
                successMessage: null,
                errorMessage: action.payload,
            };
            break;
        case GET_TICKET_ERROR:
            state = {
                ...state,
                loading: false,
                successMessage: null,
                errorMessage: action.payload,
            };
            break;

        case REPLY_TICKET:
            state = {
                ...state,
                ...action.payload,
                loading: true,
                successMessage: null,
                errorMessage: null,
            };
            break;
        case REPLY_TICKET_SUCCESS:
            let detail = state.detail;
            detail.items[0].ticket_details.unshift(action.payload);
            state = {
                ...state,
                detail: detail,
                loading: false,
                successMessage: "successfully replied to the message",
                errorMessage: null,
            };
            break;
        case REPLY_TICKET_ERROR:
            state = {
                ...state,
                loading: false,
                successMessage: null,
                errorMessage: action.payload,
            };
            break;

        case UPDATE_TICKET:
            state = {
                ...state,
                ...action.payload,
                loading: true,
                successMessage: null,
                errorMessage: null,
            };
            break;
        case UPDATE_TICKET_SUCCESS:
            state = {
                ...state,
                loading: false,
                detail: null,
                successMessage: action.payload,
                errorMessage: null,
            };
            break;
        case UPDATE_TICKET_ERROR:
            state = {
                ...state,
                loading: false,
                successMessage: null,
                errorMessage: action.payload,
            };
            break;

        case DELETE_TICKET_ITEM:
        case DELETE_TICKET:
            state = {
                ...state,
                ...action.payload,
                loading: true,
                successMessage: null,
                errorMessage: null,
            };
            break;
        case DELETE_TICKET_ITEM_SUCCESS:
        case DELETE_TICKET_SUCCESS:
            state = {
                ...state,
                items: action.payload,
                loading: false,
                successMessage: null,
                errorMessage: null,
            };
            break;
        case DELETE_TICKET_ITEM_ERROR:
        case DELETE_TICKET_ERROR:
            state = {
                ...state,
                loading: false,
                successMessage: null,
                errorMessage: action.payload,
            };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default reducer;
