import endpoint from "./endpoint";
import axios from "axios";
import { configStrict } from "./config";
export const getTicketCategory = async (payload) => {
    let config = await configStrict({
        method: "get",
        url: endpoint.API_GET_TICKET_CATEGORY,
        params: payload,
    });
    let res = await axios(config);

    return res;
};

export const createTicketCategory = async (payload) => {
    let config = await configStrict({
        method: "post",
        url: endpoint.API_CREATE_TICKET_CATEGORY,
        headers: {
            "Content-Type": "application/json",
        },
        data: JSON.stringify(payload),
    });
    let res = await axios(config);

    return res;
};

export const updateTicketCategory = async (id, payload) => {
    let config = await configStrict({
        method: "put",
        url: `${endpoint.API_UPDATE_TICKET_CATEGORY}/${id}`,
        headers: {
            "Content-Type": "application/json",
        },
        data: JSON.stringify(payload),
    });
    let res = await axios(config);

    return res;
};

export const deleteTicketCategory = async (id) => {
    let config = await configStrict({
        method: "delete",
        url: `${endpoint.API_DELETE_TICKET_CATEGORY}/${id}`,
    });
    let res = await axios(config);

    return res;
};
