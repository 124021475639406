import {
    GET_DEPARTMENT,
    GET_DEPARTMENT_SUCCESS,
    GET_DEPARTMENT_ERROR,
    CREATE_DEPARTMENT,
    CREATE_DEPARTMENT_SUCCESS,
    CREATE_DEPARTMENT_ERROR,
    UPDATE_DEPARTMENT,
    UPDATE_DEPARTMENT_SUCCESS,
    UPDATE_DEPARTMENT_ERROR,
    DELETE_DEPARTMENT,
    DELETE_DEPARTMENT_SUCCESS,
    DELETE_DEPARTMENT_ERROR,
    DEPARTMENT_INIT,
} from "./actionTypes";

export const getDepartment = (payload) => {
    return {
        type: GET_DEPARTMENT,
        payload: payload,
    };
};

export const getDepartmentSuccess = (payload) => {
    return {
        type: GET_DEPARTMENT_SUCCESS,
        payload: payload,
    };
};
export const getDepartmentError = (message) => {
    return {
        type: GET_DEPARTMENT_ERROR,
        payload: message,
    };
};

export const DepartmentInit = () => {
    return {
        type: DEPARTMENT_INIT,
    };
};

export const updateDepartment = (id, payload) => {
    return {
        type: UPDATE_DEPARTMENT,
        id: id,
        payload: payload,
    };
};

export const updateDepartmentSuccess = (message) => {
    return {
        type: UPDATE_DEPARTMENT_SUCCESS,
        payload: message,
    };
};
export const updateDepartmentError = (message) => {
    return {
        type: UPDATE_DEPARTMENT_ERROR,
        payload: message,
    };
};

export const deleteDepartment = (payload) => {
    return {
        type: DELETE_DEPARTMENT,
        payload: payload,
    };
};

export const deleteDepartmentSuccess = (message) => {
    return {
        type: DELETE_DEPARTMENT_SUCCESS,
        payload: message,
    };
};
export const deleteDepartmentError = (message) => {
    return {
        type: DELETE_DEPARTMENT_ERROR,
        payload: message,
    };
};

export const createDepartment = (payload) => {
    return {
        type: CREATE_DEPARTMENT,
        payload: payload,
    };
};

export const createDepartmentSuccess = (message) => {
    return {
        type: CREATE_DEPARTMENT_SUCCESS,
        payload: message,
    };
};
export const createDepartmentError = (message) => {
    return {
        type: CREATE_DEPARTMENT_ERROR,
        payload: message,
    };
};
