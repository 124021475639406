import endpoint from "./endpoint";
import axios from "axios";
import { configStrict } from "./config";
export const getTicketHistory = async (payload) => {
    let config = await configStrict({
        method: "get",
        url: endpoint.API_GET_TICKET,
        params: payload,
    });
    let res = await axios(config);

    return res;
};

export const replyTicket = async (payload) => {
    let config = await configStrict({
        method: "post",
        url: endpoint.API_REPLY_TICKET,
        headers: { "content-type": "multipart/form-data" },
        data: payload,
    });
    let res = await axios(config);

    return res;
};

export const updateStatusTicket = async (payload) => {
    const id = payload.id_ticket;
    delete payload.id_ticket;
    let config = await configStrict({
        method: "put",
        url: `${endpoint.API_UPDATE_STATUS_TICKET}/${id}`,
        headers: {
            "Content-Type": "application/json",
        },
        data: JSON.stringify(payload),
    });

    let res = await axios(config);

    return res;
};

export const deleteTicket = async (id) => {
    let config = await configStrict({
        method: "delete",
        url: `${endpoint.API_DELETE_TICKET}/${id}`,
    });
    let res = await axios(config);

    return res;
};

export const deleteItemTicket = async (id, id_ticket_detail) => {
    let config = await configStrict({
        method: "delete",
        url: `${endpoint.API_DELETE_ITEM_TICKET}/${id}`,
        headers: {
            "Content-Type": "application/json",
        },
        data: JSON.stringify({
            id_ticket_detail: id_ticket_detail,
        }),
    });
    let res = await axios(config);

    return res;
};
