export const GET_TICKET = "GET_TICKET";
export const GET_TICKET_SUCCESS = "GET_TICKET_SUCCESS";
export const GET_TICKET_ERROR = "GET_TICKET_ERROR";
export const GET_TICKET_DETAIL = "GET_TICKET_DETAIL";
export const GET_TICKET_DETAIL_SUCCESS = "GET_TICKET_DETAIL_SUCCESS";
export const GET_TICKET_DETAIL_ERROR = "GET_TICKET_DETAIL_ERROR";
export const TICKET_INIT = "TICKET_INIT";
export const REPLY_TICKET = "REPLY_TICKET";
export const REPLY_TICKET_SUCCESS = "REPLY_TICKET_SUCCESS";
export const REPLY_TICKET_ERROR = "REPLY_TICKET_ERROR";
export const UPDATE_TICKET = "UPDATE_TICKET";
export const UPDATE_TICKET_SUCCESS = "UPDATE_TICKET_SUCCESS";
export const UPDATE_TICKET_ERROR = "UPDATE_TICKET_ERROR";
export const DELETE_TICKET = "DELETE_TICKET";
export const DELETE_TICKET_SUCCESS = "DELETE_TICKET_SUCCESS";
export const DELETE_TICKET_ERROR = "DELETE_TICKET_ERROR";
export const DELETE_TICKET_ITEM = "DELETE_TICKET_ITEM";
export const DELETE_TICKET_ITEM_SUCCESS = "DELETE_TICKET_ITEM_SUCCESS";
export const DELETE_TICKET_ITEM_ERROR = "DELETE_TICKET_ITEM_ERROR";
