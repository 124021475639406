import {
    GET_TICKET,
    GET_TICKET_SUCCESS,
    GET_TICKET_ERROR,
    GET_TICKET_DETAIL,
    GET_TICKET_DETAIL_SUCCESS,
    GET_TICKET_DETAIL_ERROR,
    UPDATE_TICKET,
    UPDATE_TICKET_SUCCESS,
    UPDATE_TICKET_ERROR,
    DELETE_TICKET,
    DELETE_TICKET_SUCCESS,
    DELETE_TICKET_ERROR,
    DELETE_TICKET_ITEM,
    DELETE_TICKET_ITEM_SUCCESS,
    DELETE_TICKET_ITEM_ERROR,
    REPLY_TICKET,
    REPLY_TICKET_SUCCESS,
    REPLY_TICKET_ERROR,
    TICKET_INIT,
} from "./actionTypes";

export const getTicket = (payload) => {
    return {
        type: GET_TICKET,
        payload: payload,
    };
};

export const getTicketSuccess = (payload) => {
    return {
        type: GET_TICKET_SUCCESS,
        payload: payload,
    };
};
export const getTicketError = (message) => {
    return {
        type: GET_TICKET_ERROR,
        payload: message,
    };
};

export const getTicketDetail = (payload) => {
    return {
        type: GET_TICKET_DETAIL,
        payload: payload,
    };
};

export const getTicketDetailSuccess = (payload) => {
    return {
        type: GET_TICKET_DETAIL_SUCCESS,
        payload: payload,
    };
};
export const getTicketDetailError = (message) => {
    return {
        type: GET_TICKET_DETAIL_ERROR,
        payload: message,
    };
};

export const ticketInit = () => {
    return {
        type: TICKET_INIT,
    };
};

export const replyTicket = (payload) => {
    return {
        type: REPLY_TICKET,
        payload: payload,
    };
};

export const replyTicketSuccess = (message) => {
    return {
        type: REPLY_TICKET_SUCCESS,
        payload: message,
    };
};
export const replyTicketError = (message) => {
    return {
        type: REPLY_TICKET_ERROR,
        payload: message,
    };
};

export const updateTicket = (payload) => {
    return {
        type: UPDATE_TICKET,
        payload: payload,
    };
};

export const updateTicketSuccess = (message) => {
    return {
        type: UPDATE_TICKET_SUCCESS,
        payload: message,
    };
};
export const updateTicketError = (message) => {
    return {
        type: UPDATE_TICKET_ERROR,
        payload: message,
    };
};

export const deleteTicket = (payload) => {
    return {
        type: DELETE_TICKET,
        payload: payload,
    };
};

export const deleteTicketSuccess = (message) => {
    return {
        type: DELETE_TICKET_SUCCESS,
        payload: message,
    };
};
export const deleteTicketError = (message) => {
    return {
        type: DELETE_TICKET_ERROR,
        payload: message,
    };
};

export const deleteItemTicket = (payload) => {
    return {
        type: DELETE_TICKET_ITEM,
        payload: payload,
    };
};

export const deleteItemTicketSuccess = (message) => {
    return {
        type: DELETE_TICKET_ITEM_SUCCESS,
        payload: message,
    };
};
export const deleteItemTicketError = (message) => {
    return {
        type: DELETE_TICKET_ITEM_ERROR,
        payload: message,
    };
};
