export const GET_TICKET_CATEGORY = "GET_TICKET_CATEGORY";
export const GET_TICKET_CATEGORY_SUCCESS = "GET_TICKET_CATEGORY_SUCCESS";
export const GET_TICKET_CATEGORY_ERROR = "GET_TICKET_CATEGORY_ERROR";
export const TICKET_CATEGORY_INIT = "TICKET_INIT";
export const UPDATE_TICKET_CATEGORY = "UPDATE_TICKET_CATEGORY";
export const UPDATE_TICKET_CATEGORY_SUCCESS = "UPDATE_TICKET_CATEGORY_SUCCESS";
export const UPDATE_TICKET_CATEGORY_ERROR = "UPDATE_TICKET_CATEGORY_ERROR";
export const CREATE_TICKET_CATEGORY = "CREATE_TICKET_CATEGORY";
export const CREATE_TICKET_CATEGORY_SUCCESS = "CREATE_TICKET_CATEGORY_SUCCESS";
export const CREATE_TICKET_CATEGORY_ERROR = "CREATE_TICKET_CATEGORY_ERROR";
export const DELETE_TICKET_CATEGORY = "DELETE_TICKET_CATEGORY";
export const DELETE_TICKET_CATEGORY_SUCCESS = "DELETE_TICKET_CATEGORY_SUCCESS";
export const DELETE_TICKET_CATEGORY_ERROR = "DELETE_TICKET_CATEGORY_ERROR";
