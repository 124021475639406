import React from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { login } from "src/store/actions";
import LogoHorizontal from "src/assets/dist/img/logo-horizontal.png";

const Signin = (props) => {
    const [alert, setAlert] = useState({
        show: false,
        message: "",
    });
    const dispatch = useDispatch();
    const loginState = useSelector((state) => state.Signin);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const doSignin = () => {
        dispatch(
            login(
                {
                    email: email,
                    password: password,
                },
                props.history
            )
        );
    };
    useEffect(() => {
        if (loginState.errorMessage !== null) {
            setAlert({
                show: true,
                message: loginState.errorMessage,
            });
        }
    }, [loginState.errorMessage]);
    return (
        <div>
            {alert.show && (
                <div className="alert alert-danger alert-dismissible">
                    <button
                        type="button"
                        onClick={() => {
                            setAlert({
                                ...alert,
                                show: false,
                            });
                        }}
                        className="close"
                        data-dismiss="alert"
                        aria-hidden="true"
                    >
                        ×
                    </button>
                    {alert.message}
                </div>
            )}

            <div className="card card-outline card-primary">
                <div className="card-header text-center">
                    <img
                        src={LogoHorizontal}
                        alt="Logo Bidbox"
                        style={{ width: 200 }}
                    />
                </div>
                <div className="card-body">
                    <p className="login-box-msg">
                        Sign in to start your session
                    </p>
                    <div className="input-group mb-3">
                        <input
                            type="email"
                            onChange={(e) => setEmail(e.target.value)}
                            className="form-control"
                            placeholder="Email"
                        />
                        <div className="input-group-append">
                            <div className="input-group-text">
                                <span className="fas fa-envelope"></span>
                            </div>
                        </div>
                    </div>
                    <div className="input-group mb-3">
                        <input
                            onChange={(e) => setPassword(e.target.value)}
                            type="password"
                            className="form-control"
                            placeholder="Password"
                        />
                        <div className="input-group-append">
                            <div className="input-group-text">
                                <span className="fas fa-lock"></span>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-8">
                            <div className="icheck-primary">
                                <input type="checkbox" id="remember" />
                                <label htmlFor="remember">Remember Me</label>
                            </div>
                        </div>
                        <div className="col-4">
                            <button
                                onClick={doSignin}
                                type="button"
                                className="btn btn-primary btn-block"
                            >
                                Sign In
                            </button>
                        </div>
                    </div>

                    <p className="mb-1">
                        <Link to="/auth/forgot-password">
                            I forgot my password
                        </Link>
                    </p>
                </div>
            </div>
        </div>
    );
};
export default Signin;
