import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
    getTicket,
    getTicketDetail,
    ticketInit,
    replyTicket,
    updateTicket,
} from "src/store/actions";
import Table from "src/components/table/Table";
import LogoBidbox from "src/assets/dist/img/logo.png";
const TradersList = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [file, setFile] = useState(null);
    const [keyFile, setKeyFile] = useState(1);
    const [alert, setAlert] = useState({
        show: false,
        type: "info",
        message: "",
    });

    const [modal, setModal] = useState({
        isVisible: false,
    });
    const dispatch = useDispatch();
    const ticketState = useSelector((state) => state.Ticket);
    const [search, setSearch] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [reply, setReply] = useState("");
    const [lastIdTicket, setLastIdTicket] = useState(null);
    const [itemsTicket, setItemsTicket] = useState([]);
    const [statusOpen, setStatusOpen] = useState(true);
    const [statusProgress, setStatusProgress] = useState(true);
    const [statusClose, setStatusClose] = useState(false);
    useEffect(() => {
        let status = [];
        if (statusOpen === true) status.push(1);
        if (statusProgress === true) status.push(2);
        if (statusClose === true) status.push(3);
        if (status.length === 0) status.push(4);
        setCurrentPage(1);
        load_history_ticket(status);
    }, [statusOpen, statusProgress, statusClose]);
    const updateStatusTicket = (last_status) => {
        const title = last_status !== 3 ? "Close Ticket" : "Open Ticket";
        setModal({
            ...modal,
            title: title,
            isVisible: true,
            id: lastIdTicket,
        });
    };
    const submitModal = () => {
        setModal({
            ...modal,
            isVisible: false,
        });
        let status = 3;
        if (ticketState.detail.items[0].status !== 3) {
            status = 3;
        } else {
            status = 2;
        }
        dispatch(
            updateTicket({
                id_ticket: ticketState.detail.items[0].id,
                status: status,
            })
        );
    };
    useEffect(() => {
        try {
            if (Array.isArray(ticketState.items)) {
                setItemsTicket([...itemsTicket, ...ticketState.items]);
            } else {
                setItemsTicket([...itemsTicket]);
            }
        } catch (error) {
            console.log(error.message);
        }
    }, [ticketState.items]);
    const doReplyTicket = () => {
        if (reply === "") {
            setAlert({
                show: true,
                type: "danger",
                message: "Please enter your reply",
            });
        } else {
            let formData = new FormData();
            if (file !== null) formData.append("file", file);
            formData.append("id_ticket", ticketState.detail.items[0].id);
            formData.append("description", reply);
            dispatch(replyTicket(formData));
        }
    };
    const load_history_ticket = (status, nextPage = null) => {
        let current_page = nextPage === null ? currentPage : nextPage;
        dispatch(
            getTicket({
                page: current_page,
                per_page: 100,
                filter_status: status,
            })
        );
    };
    const load_detail_ticket = (id_ticket) => {
        setLastIdTicket(id_ticket);
        dispatch(
            getTicketDetail({
                id: id_ticket,
                page: 1,
                per_page: 1,
            })
        );
    };
    useEffect(() => {
        if (
            ticketState.successMessage !== null &&
            ticketState.successMessage !== ""
        ) {
            setAlert({
                show: true,
                type: "success",
                message: ticketState.successMessage,
            });
            setReply("");
            setFile(null);
            setKeyFile(Math.random().toString(36));
            let status = [];
            if (statusOpen === true) status.push(1);
            if (statusProgress === true) status.push(2);
            if (statusClose === true) status.push(3);
            if (status.length === 0) status.push(4);
            load_history_ticket(status);
            load_detail_ticket(lastIdTicket);
        }

        if (
            ticketState.errorMessage !== null &&
            ticketState.errorMessage !== ""
        ) {
            setAlert({
                show: true,
                type: "danger",
                message: ticketState.errorMessage,
            });
        }
    }, [ticketState.successMessage, ticketState.errorMessage]);
    useEffect(() => {
        ticketInit();
        return () => {
            ticketInit();
        };
    }, []);
    useEffect(() => {
        setIsLoading(ticketState.loading);
    }, [ticketState.loading]);
    const next_page = () => {
        let status = [];
        if (statusOpen === true) status.push(1);
        if (statusProgress === true) status.push(2);
        if (statusClose === true) status.push(3);
        if (status.length === 0) status.push(4);
        const nextPage = currentPage + 1;
        setCurrentPage(nextPage);
        load_history_ticket(status, nextPage);
    };
    return (
        <div>
            <div
                className={`modal fade ${isLoading ? "show" : ""}`}
                id="modal-default"
                aria-modal="true"
                style={{
                    display: `${isLoading ? "block" : "none"}`,
                    backgroundColor: "rgba(0,0,0,0.5)",
                }}
                role="dialog"
            >
                <div className="modal-dialog modal-sm" style={{ width: 150 }}>
                    <div className="modal-content">
                        <div
                            className="modal-body"
                            style={{ textAlign: "center" }}
                        >
                            <img
                                src={LogoBidbox}
                                alt="LogoBidbox"
                                width={50}
                                style={{}}
                            />
                            <h5>Loading...</h5>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Ticket</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item">
                                    <Link to="#">Home</Link>
                                </li>
                                <li className="breadcrumb-item active">
                                    Ticket
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            {alert.show && (
                                <div
                                    className={`alert alert-${alert.type} alert-dismissible`}
                                >
                                    <button
                                        type="button"
                                        onClick={() => {
                                            setAlert({
                                                ...alert,
                                                show: false,
                                            });
                                        }}
                                        className="close"
                                        data-dismiss="alert"
                                        aria-hidden="true"
                                    >
                                        ×
                                    </button>
                                    {alert.message}
                                </div>
                            )}
                        </div>
                        <div className="col-lg-4">
                            <div className="card">
                                <div className="card-header">
                                    <div className="row">
                                        <div className="col-12">
                                            <h3 className="card-title">
                                                Ticket
                                            </h3>
                                            <div
                                                className="card-tools"
                                                style={{ display: "none" }}
                                            >
                                                <div
                                                    className="input-group input-group-sm"
                                                    style={{ width: 150 }}
                                                >
                                                    <input
                                                        type="text"
                                                        name="table_search"
                                                        className="form-control float-right"
                                                        placeholder="Search"
                                                        onChange={(e) =>
                                                            setSearch(
                                                                e.target.value
                                                            )
                                                        }
                                                    />

                                                    <div className="input-group-append">
                                                        <button
                                                            type="button"
                                                            className="btn btn-default"
                                                        >
                                                            <i className="fas fa-search"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="row"
                                            style={{ marginTop: 10 }}
                                        >
                                            <div className="col-4">
                                                <div className="icheck-primary">
                                                    <input
                                                        type="checkbox"
                                                        value="1"
                                                        id="status1"
                                                        onClick={() =>
                                                            setStatusOpen(
                                                                !statusOpen
                                                            )
                                                        }
                                                        defaultChecked={
                                                            statusOpen
                                                        }
                                                    />
                                                    <label htmlFor="status1">
                                                        Open
                                                    </label>
                                                </div>
                                            </div>

                                            <div className="col-6">
                                                <div className="icheck-primary">
                                                    <input
                                                        type="checkbox"
                                                        value="2"
                                                        id="status2"
                                                        onClick={() =>
                                                            setStatusProgress(
                                                                !statusProgress
                                                            )
                                                        }
                                                        defaultChecked={
                                                            statusProgress
                                                        }
                                                    />
                                                    <label htmlFor="status2">
                                                        On Progress
                                                    </label>
                                                </div>
                                            </div>

                                            <div className="col-2">
                                                <div className="icheck-primary">
                                                    <input
                                                        type="checkbox"
                                                        value="3"
                                                        id="status3"
                                                        onClick={() =>
                                                            setStatusClose(
                                                                !statusClose
                                                            )
                                                        }
                                                        defaultChecked={
                                                            statusClose
                                                        }
                                                    />
                                                    <label htmlFor="status3">
                                                        Close
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="card-body"
                                    style={{
                                        padding: 5,
                                        maxHeight: 500,
                                        overflowY: "scroll",
                                    }}
                                >
                                    <ul className="nav flex-column">
                                        {itemsTicket.map((k, v) => (
                                            <li
                                                key={v}
                                                className="nav-item"
                                                style={{
                                                    cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                    load_detail_ticket(k.id)
                                                }
                                            >
                                                <div className="nav-link">
                                                    <span
                                                        className="float-left"
                                                        style={{
                                                            fontSize: 12,
                                                        }}
                                                    >
                                                        {"ID Ticket : "}
                                                        {k.id} <br />
                                                        <span>
                                                            {k.subject !==
                                                                null &&
                                                            k.subject !== ""
                                                                ? k.subject
                                                                : ""}
                                                        </span>
                                                        <br />
                                                        <span className="text-gray">
                                                            {k.user_client !==
                                                            null
                                                                ? k.user_client
                                                                      ?.full_name +
                                                                  " (" +
                                                                  k.user_client
                                                                      ?.email +
                                                                  ")"
                                                                : ""}
                                                            <br />
                                                            {
                                                                k
                                                                    .master_ticket_category
                                                                    ?.name
                                                            }
                                                        </span>
                                                    </span>

                                                    <span
                                                        style={{
                                                            fontSize: 12,
                                                            color: "grey",
                                                            textAlign: "right",
                                                        }}
                                                        className="float-right"
                                                    >
                                                        {new Date(
                                                            k.created_at
                                                        ).toLocaleString(
                                                            "en-GB"
                                                        )}
                                                        <br />

                                                        <span
                                                            style={
                                                                k.is_answer_client ===
                                                                false
                                                                    ? {
                                                                          color: "green",
                                                                      }
                                                                    : {
                                                                          color: "red",
                                                                      }
                                                            }
                                                        >
                                                            {k.status === 1 ? (
                                                                <span
                                                                    style={{
                                                                        color: "green",
                                                                    }}
                                                                >
                                                                    open
                                                                    <br />
                                                                </span>
                                                            ) : k.status ===
                                                              2 ? (
                                                                <span
                                                                    style={{
                                                                        color: "orange",
                                                                    }}
                                                                >
                                                                    on progress
                                                                    <br />
                                                                </span>
                                                            ) : (
                                                                <span
                                                                    style={{
                                                                        color: "red",
                                                                    }}
                                                                >
                                                                    closed
                                                                    <br />
                                                                </span>
                                                            )}{" "}
                                                            {k.is_answer_client ===
                                                            false
                                                                ? "answered"
                                                                : "customer answered"}
                                                        </span>
                                                        <br />
                                                    </span>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="card-footer">
                                    <button
                                        className="btn btn-xs btn-primary"
                                        onClick={next_page}
                                    >
                                        Load More
                                    </button>
                                </div>
                            </div>
                        </div>
                        {ticketState.detail !== null && (
                            <div className="col-lg-8">
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">
                                            Detail Ticket
                                            <br />
                                            <span style={{ fontSize: 12 }}>
                                                ID Ticket :{" "}
                                                {ticketState.detail.items[0].id}
                                                <br />
                                                {
                                                    ticketState.detail.items[0]
                                                        .master_ticket_category
                                                        .name
                                                }
                                                <br />
                                                <span>
                                                    Username :{" "}
                                                    {
                                                        ticketState.detail
                                                            .items[0]
                                                            .user_client
                                                            .username
                                                    }
                                                </span>
                                            </span>
                                        </h3>
                                        <div className="card-tools float-right">
                                            <button
                                                onClick={() =>
                                                    updateStatusTicket(
                                                        ticketState.detail
                                                            .items[0].status
                                                    )
                                                }
                                                className={`btn ${
                                                    ticketState.detail.items[0]
                                                        .status !== 3
                                                        ? "btn-danger"
                                                        : "btn-primary"
                                                } btn-xs`}
                                            >
                                                {ticketState.detail.items[0]
                                                    .status !== 3
                                                    ? "Close"
                                                    : "Open"}{" "}
                                                Ticket
                                            </button>
                                        </div>
                                    </div>
                                    <div
                                        className="card-body"
                                        style={{
                                            padding: 5,
                                            overflowY: "scroll",
                                            maxHeight: 500,
                                        }}
                                    >
                                        <ul className="nav flex-column">
                                            {ticketState.detail.items !==
                                                null &&
                                                ticketState.detail.items[0]
                                                    .ticket_details &&
                                                ticketState.detail.items[0].ticket_details.map(
                                                    (k, v) => (
                                                        <li
                                                            key={v}
                                                            className="nav-item"
                                                        >
                                                            <div className="nav-link">
                                                                <span
                                                                    className="float-left"
                                                                    style={{
                                                                        fontSize: 12,
                                                                        color: "grey",
                                                                    }}
                                                                >
                                                                    <span
                                                                        style={
                                                                            k.is_client ===
                                                                            true
                                                                                ? {
                                                                                      fontSize: 12,
                                                                                      color: "green",
                                                                                  }
                                                                                : {
                                                                                      fontSize: 12,
                                                                                  }
                                                                        }
                                                                    >
                                                                        {k.is_client ===
                                                                        true
                                                                            ? ticketState
                                                                                  .detail
                                                                                  .items[0]
                                                                                  .user_client
                                                                                  .email
                                                                            : "Me"}
                                                                    </span>
                                                                    <br />
                                                                    <span
                                                                        style={{
                                                                            whiteSpace:
                                                                                "pre-wrap",
                                                                        }}
                                                                    >
                                                                        {
                                                                            k.description
                                                                        }
                                                                    </span>

                                                                    <div
                                                                        style={{
                                                                            textAlign:
                                                                                "left",
                                                                            margin: 10,
                                                                        }}
                                                                    >
                                                                        {k.attachment_image !==
                                                                            null && (
                                                                            <a
                                                                                href={
                                                                                    k.attachment_image
                                                                                }
                                                                                target="_blank"
                                                                                rel="noreferrer"
                                                                            >
                                                                                <img
                                                                                    src={
                                                                                        k.attachment_image
                                                                                    }
                                                                                    width="50%"
                                                                                    alt="img-attachment"
                                                                                />
                                                                            </a>
                                                                        )}
                                                                    </div>
                                                                </span>

                                                                <span
                                                                    style={{
                                                                        fontSize: 12,
                                                                        color: "grey",
                                                                        textAlign:
                                                                            "right",
                                                                    }}
                                                                    className="float-right"
                                                                >
                                                                    {new Date(
                                                                        k.created_at
                                                                    ).toLocaleString(
                                                                        "en-GB"
                                                                    )}
                                                                </span>
                                                            </div>
                                                        </li>
                                                    )
                                                )}
                                        </ul>
                                    </div>
                                    <div className="card-footer row">
                                        <div style={{ margin: 10 }}>
                                            <div className="field">
                                                <input
                                                    type="file"
                                                    name="file"
                                                    id="attach-image"
                                                    accept="image/*"
                                                    key={keyFile}
                                                    onChange={(e) =>
                                                        setFile(
                                                            e.target.files[0]
                                                        )
                                                    }
                                                    onClick={(event) =>
                                                        (event.target.value =
                                                            null)
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <textarea
                                            onChange={(v) =>
                                                setReply(v.target.value)
                                            }
                                            className="form-control col-10"
                                            rows={2}
                                        >
                                            {reply}
                                        </textarea>
                                        <button
                                            onClick={doReplyTicket}
                                            className="btn btn-primary float-right col-1"
                                            style={{ marginLeft: 10 }}
                                        >
                                            Send
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div
                    className={`modal fade ${modal.isVisible ? "show" : ""}`}
                    id="modal-default"
                    aria-modal="true"
                    style={{
                        display: modal.isVisible ? "block" : "none",
                        backgroundColor: "rgba(0,0,0,0.5)",
                    }}
                    role="dialog"
                >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">{modal.title}</h4>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span
                                        aria-hidden="true"
                                        onClick={() =>
                                            setModal({
                                                ...modal,
                                                isVisible: false,
                                            })
                                        }
                                    >
                                        ×
                                    </span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td style={{ width: 150 }}>
                                                ID Ticket
                                            </td>
                                            <td>: {modal.id}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="modal-footer justify-content-between">
                                <button
                                    type="button"
                                    className="btn btn-default"
                                    data-dismiss="modal"
                                    onClick={() =>
                                        setModal({
                                            ...modal,
                                            isVisible: false,
                                        })
                                    }
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => {
                                        submitModal(
                                            ticketState.detail.items[0].status
                                        );
                                    }}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};
export default TradersList;
