import { takeLatest, put, call } from "redux-saga/effects";

import {
    GET_USERS,
    CREATE_USERS,
    UPDATE_USERS,
    DELETE_USERS,
} from "./actionTypes";
import {
    getUsersSuccess,
    getUsersError,
    createUsersSuccess,
    createUsersError,
    deleteUsersSuccess,
    deleteUsersError,
    updateUsersSuccess,
    updateUsersError,
} from "./actions";

import {
    getUser as apiGetUsers,
    createUser as apiCreateUsers,
    updateUser as apiUpdateUsers,
    deleteUser as apiDeleteUsers,
} from "src/api";
function* getUsers({ payload }) {
    try {
        const response = yield call(apiGetUsers, payload);
        const data = response.data;
        yield put(getUsersSuccess(data));
    } catch (error) {
        let message = String(error);
        try {
            message = error.response.data.message;
        } catch (q) {}
        yield put(getUsersError(message));
    }
}

function* createUsers({ payload }) {
    try {
        yield call(apiCreateUsers, payload);
        yield put(createUsersSuccess("successful user created"));
    } catch (error) {
        let message = String(error);
        try {
            message = error.response.data.message;
        } catch (q) {}
        yield put(createUsersError(message));
    }
}

function* updateUsers({ id, payload }) {
    try {
        yield call(apiUpdateUsers, id, payload);
        yield put(updateUsersSuccess("successful user update"));
    } catch (error) {
        let message = String(error);
        try {
            message = error.response.data.message;
        } catch (q) {}
        yield put(updateUsersError(message));
    }
}

function* deleteUsers({ payload }) {
    try {
        yield call(apiDeleteUsers, payload);
        yield put(deleteUsersSuccess("successful user deleted"));
    } catch (error) {
        let message = String(error);
        try {
            message = error.response.data.message;
        } catch (q) {}
        yield put(deleteUsersError(message));
    }
}

function* authSaga() {
    yield takeLatest(GET_USERS, getUsers);
    yield takeLatest(CREATE_USERS, createUsers);
    yield takeLatest(UPDATE_USERS, updateUsers);
    yield takeLatest(DELETE_USERS, deleteUsers);
}

export default authSaga;
