import { takeLatest, put, call } from "redux-saga/effects";

import {
    GET_TICKET_CATEGORY,
    CREATE_TICKET_CATEGORY,
    UPDATE_TICKET_CATEGORY,
    DELETE_TICKET_CATEGORY,
} from "./actionTypes";
import {
    getTicketCategorySuccess,
    getTicketCategoryError,
    createTicketCategorySuccess,
    createTicketCategoryError,
    deleteTicketCategorySuccess,
    deleteTicketCategoryError,
    updateTicketCategorySuccess,
    updateTicketCategoryError,
} from "./actions";

import {
    getTicketCategory as apiGetTicketCategory,
    createTicketCategory as apiCreateTicketCategory,
    updateTicketCategory as apiUpdateTicketCategory,
    deleteTicketCategory as apiDeleteTicketCategory,
} from "src/api";
function* getTicketCategory({ payload }) {
    try {
        const response = yield call(apiGetTicketCategory, payload);
        const data = response.data;
        yield put(getTicketCategorySuccess(data));
    } catch (error) {
        let message = String(error);
        try {
            message = error.response.data.message;
        } catch (q) {}
        yield put(getTicketCategoryError(message));
    }
}

function* createTicketCategory({ payload }) {
    try {
        yield call(apiCreateTicketCategory, payload);
        yield put(
            createTicketCategorySuccess("successful ticket category created")
        );
    } catch (error) {
        let message = String(error);
        try {
            message = error.response.data.message;
        } catch (q) {}
        yield put(createTicketCategoryError(message));
    }
}

function* updateTicketCategory({ id, payload }) {
    try {
        yield call(apiUpdateTicketCategory, id, payload);
        yield put(
            updateTicketCategorySuccess("successful ticket category update")
        );
    } catch (error) {
        let message = String(error);
        try {
            message = error.response.data.message;
        } catch (q) {}
        yield put(updateTicketCategoryError(message));
    }
}

function* deleteTicketCategory({ payload }) {
    try {
        yield call(apiDeleteTicketCategory, payload);
        yield put(
            deleteTicketCategorySuccess("successful ticket category deleted")
        );
    } catch (error) {
        let message = String(error);
        try {
            message = error.response.data.message;
        } catch (q) {}
        yield put(deleteTicketCategoryError(message));
    }
}

function* authSaga() {
    yield takeLatest(GET_TICKET_CATEGORY, getTicketCategory);
    yield takeLatest(CREATE_TICKET_CATEGORY, createTicketCategory);
    yield takeLatest(UPDATE_TICKET_CATEGORY, updateTicketCategory);
    yield takeLatest(DELETE_TICKET_CATEGORY, deleteTicketCategory);
}

export default authSaga;
