// Layout
import AuthLayout from "src/components/layout/AuthLayout";
import AdminLayout from "src/components/layout/AdminLayout";

// Authentication
import Logout from "src/pages/Authentication/Logout";
import Signin from "src/pages/Authentication/Signin";
import TicketList from "src/pages/Ticket/TicketList";
// Ticket Category
import TicketCategoryList from "src/pages/TicketCategory/TicketCategoryList";
import TicketCategoryCreate from "src/pages/TicketCategory/TicketCategoryCreate";
import TicketCategoryEdit from "src/pages/TicketCategory/TicketCategoryEdit";
// Department
import DepartmentList from "src/pages/Department/DepartmentList";
import DepartmentCreate from "src/pages/Department/DepartmentCreate";
import DepartmentEdit from "src/pages/Department/DepartmentEdit";
// Users
import UserList from "src/pages/Users/UserList";
import UserCreate from "src/pages/Users/UserCreate";
import UserEdit from "src/pages/Users/UserEdit";
const authRoutes = [
    { path: "/auth/signin", component: Signin, layout: AuthLayout },
];

const adminRoutes = [
    { path: "/logout", component: Logout, layout: AdminLayout },

    {
        path: "/user/create",
        component: UserCreate,
        layout: AdminLayout,
    },
    {
        path: "/ticket-category/edit/:id",
        component: TicketCategoryEdit,
        layout: AdminLayout,
    },
    {
        path: "/ticket-category/create",
        component: TicketCategoryCreate,
        layout: AdminLayout,
    },
    {
        path: "/department/edit/:id",
        component: DepartmentEdit,
        layout: AdminLayout,
    },
    {
        path: "/user/edit/:id",
        component: UserEdit,
        layout: AdminLayout,
    },
    {
        path: "/department/create",
        component: DepartmentCreate,
        layout: AdminLayout,
    },
    {
        path: "/ticket",
        component: TicketList,
        layout: AdminLayout,
    },
    {
        path: "/ticket-category",
        component: TicketCategoryList,
        layout: AdminLayout,
    },
    {
        path: "/department",
        component: DepartmentList,
        layout: AdminLayout,
    },
    {
        path: "/user",
        component: UserList,
        layout: AdminLayout,
    },
];

const nonAuthRoutes = [];

export { authRoutes, adminRoutes, nonAuthRoutes };
