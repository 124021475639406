// import assets css
import { useRef } from "react";
import Footer from "src/components/footer/Footer";
import Navbar from "src/components/navbar/Navbar";
import Sidebar from "src/components/sidebar/Sidebar";
import Logo from "src/assets/dist/img/logo.png";
import UserImage from "src/assets/dist/img/user2-160x160.jpg";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
// import { getProfile } from "src/store/actions";

import "src/assets/dist/css/fonts.css";
import "src/assets/plugins/icheck-bootstrap/icheck-bootstrap.min.css";
import "src/assets/dist/css/adminlte.min.css";
import "src/assets/dist/js/adminlte.js";
import "src/assets/dist/js/demo.js";
import { parseJwt } from "src/utils/helpers";

const AdminLayout = (props) => {
    const dispatch = useDispatch();
    let menu = useRef([]);
    const accountState = useSelector((state) => state.Account);
    const authStorage = JSON.parse(
        localStorage.getItem("bidbox_support_ticket_authUser")
    );
    const token = authStorage.jwt_token;
    const decodeToken = parseJwt(token);

    let menu_master = [
        {
            label: "Ticket",
            icon: "fas fa-question-circle",
            link: "/ticket",
            items: [],
        },

        {
            label: "Ticket Category",
            icon: "fas fa-bookmark",
            link: "/ticket-category",
            items: [],
        },
        {
            label: "Department",
            icon: "fas fa-bookmark",
            link: "/department",
            items: [],
        },
        {
            label: "User",
            icon: "fas fa-users",
            link: "/user",
            items: [],
        },
    ];

    useEffect(() => {
        // dispatch(getProfile());
    }, []);
    const generateMenu = (menus) => {
        const pathname = props.children.props.history.location.pathname;

        if (menus.items && menus.items.length > 0) {
            menus.items.forEach((e1, i1) => {
                let isActive1 = false;
                let isActive2 = false;
                if (e1.items && e1.items.length > 0) {
                    e1.items.forEach((e2, i2) => {
                        if (e2.items && e2.items.length > 0) {
                            e2.items.forEach((e3, i3) => {
                                if (e3.link === pathname) {
                                    e3.isActive = true;
                                    isActive2 = true;
                                } else {
                                    e3.isActive = false;
                                }
                            });
                            if (isActive2) {
                                e2.isActive = true;
                            }
                        }
                        if (e2.link === pathname) {
                            isActive1 = true;
                            e2.isActive = true;
                        } else {
                            e2.isActive = false;
                        }
                        if (isActive2) {
                            e2.isActive = true;
                        }
                    });
                }
                if (e1.link === pathname) {
                    e1.isActive = true;
                    isActive1 = true;
                } else {
                    e1.isActive = false;
                }

                if (isActive1) {
                    e1.isActive = true;
                }
            });
        }
        return menus;
    };
    menu = generateMenu({
        items: menu_master,
    });

    return (
        <div className="wrapper">
            <Navbar
                leftNavbar={{
                    isVisible: false,
                    items: [],
                }}
                searchNavbar={{
                    isVisible: false,
                }}
                accountDropdown={{
                    isVisible: true,
                    avatar: "https://evcuzz.sgp1.digitaloceanspaces.com/img/img/avatar-blank.jpeg",
                    title: "accountState.full_name",
                    subTitle: "accountState.email",
                    roleName: "Administrator",
                    items: [
                        {
                            label: "Change Profile",
                            icon: "fas fa-user mr-2",
                            action: () => {
                                props.children.props.history.push(
                                    "/change-profile"
                                );
                            },
                        },
                        {
                            label: "Change Password",
                            icon: "fas fa-lock mr-2",
                            action: () => {
                                props.children.props.history.push(
                                    "/change-password"
                                );
                            },
                        },
                        {
                            label: "Logout",
                            icon: "fas fa-sign-out-alt mr-2",
                            action: () => {
                                props.children.props.history.push("/logout");
                            },
                        },
                    ],
                }}
            />
            <Sidebar
                logoTitle="Bidbox"
                logo={Logo}
                userName={"accountState.full_name"}
                avatar="https://evcuzz.sgp1.digitaloceanspaces.com/img/img/avatar-blank.jpeg"
                history={props.children.props.history}
                menus={menu}
            />
            <div className="content-wrapper">{props.children}</div>
            <Footer
                props={props}
                version="1.0.0"
                copyright={
                    <span>
                        Copyright &copy; 2022{" "}
                        <Link to="#">Bidbox Community</Link>
                    </span>
                }
            />
        </div>
    );
};
export default AdminLayout;
