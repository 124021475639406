const Footer = ({ props, version, copyright }) => {
    return (
        <footer className="main-footer">
            <div className="float-right d-none d-sm-block">
                <b>Version</b> {version}
            </div>
            <strong>{copyright}</strong>
        </footer>
    );
};
export default Footer;
