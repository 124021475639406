import endpoint from "./endpoint";
import axios from "axios";
import { configStrict } from "./config";
export const getDepartment = async (payload) => {
    let config = await configStrict({
        method: "get",
        url: endpoint.API_GET_DEPARTMENT,
        params: payload,
    });
    let res = await axios(config);

    return res;
};

export const createDepartment = async (payload) => {
    let config = await configStrict({
        method: "post",
        url: endpoint.API_CREATE_DEPARTMENT,
        headers: {
            "Content-Type": "application/json",
        },
        data: JSON.stringify(payload),
    });
    let res = await axios(config);

    return res;
};

export const updateDepartment = async (id, payload) => {
    let config = await configStrict({
        method: "put",
        url: `${endpoint.API_UPDATE_DEPARTMENT}/${id}`,
        headers: {
            "Content-Type": "application/json",
        },
        data: JSON.stringify(payload),
    });
    let res = await axios(config);

    return res;
};

export const deleteDepartment = async (id) => {
    let config = await configStrict({
        method: "delete",
        url: `${endpoint.API_DELETE_DEPARTMENT}/${id}`,
    });
    let res = await axios(config);

    return res;
};
