import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getUsers, deleteUsers } from "src/store/actions";
import Table from "src/components/table/Table";
const UsersList = () => {
    const [alert, setAlert] = useState({
        show: false,
        type: "info",
        message: "",
    });

    const [modal, setModal] = useState({
        isVisible: false,
    });
    const dispatch = useDispatch();
    const UsersState = useSelector((state) => state.Users);
    const [Users, setUsers] = useState({
        items: [],
        totalItems: 0,
        totalFiltered: 0,
        limit: 10,
        page: 1,
    });

    const loadUsers = ({ page, search, limit }) => {
        dispatch(
            getUsers({
                search: search,
                page: page,
                per_page: limit,
                sort: [["id", "ASC"]],
            })
        );
    };
    useEffect(() => {});
    useEffect(() => {
        let data_category = [];
        UsersState.items.forEach((el) => {
            data_category.push([
                <div>
                    <Link
                        className="btn btn-xs btn-primary"
                        to={`/user/edit/${el.id}`}
                    >
                        <i className="fas fa-pencil"></i>
                    </Link>
                    &nbsp;
                    <button
                        className="btn btn-xs btn-danger"
                        onClick={() => showModalDelete(el.id, el.email)}
                    >
                        <i className="fas fa-trash"></i>
                    </button>
                </div>,
                el.id,
                el.name,
                el.email,
                el.role,
                el.status,
                new Date(el.created_at).toLocaleString("en-GB"),
            ]);
        });
        setUsers({
            totalItems: UsersState.total_items,
            totalFiltered: UsersState.total_items,
            limit: UsersState.per_page,
            page: UsersState.current_page,
            items: data_category,
        });
    }, [UsersState.items]);

    const submitModal = (id) => {
        dispatch(deleteUsers(id));
        setModal({
            isVisible: false,
        });
    };
    const showModalDelete = (id, email) => {
        setModal({
            isVisible: true,
            id: id,
            email: email,
            title: "Delete",
        });
    };

    useEffect(() => {
        if (UsersState.successMessage !== null) {
            setAlert({
                type: "info",
                message: UsersState.successMessage,
                show: true,
            });
            loadUsers({
                page: 1,
                limit: 100,
                search: "",
            });
        }

        if (UsersState.errorMessage !== null) {
            setAlert({
                type: "danger",
                message: UsersState.errorMessage,
                show: true,
            });
        }
    }, [UsersState.successMessage, UsersState.errorMessage]);
    return (
        <div>
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">User</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item">
                                    <Link to="#">Home</Link>
                                </li>
                                <li className="breadcrumb-item active">
                                    Users
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            {alert.show && (
                                <div
                                    className={`alert alert-${alert.type} alert-dismissible`}
                                >
                                    <button
                                        type="button"
                                        onClick={() => {
                                            setAlert({
                                                ...alert,
                                                show: false,
                                            });
                                        }}
                                        className="close"
                                        data-dismiss="alert"
                                        aria-hidden="true"
                                    >
                                        ×
                                    </button>
                                    {alert.message}
                                </div>
                            )}
                            <Table
                                searching={false}
                                title="Users"
                                paging={true}
                                colHeader={[
                                    "#",
                                    "ID",
                                    "Full Name",
                                    "Email",
                                    "Role",
                                    "Status",
                                    "Created at",
                                ]}
                                data={{
                                    items: Users.items,
                                    totalFiltered: Users.totalFiltered,
                                    totalItems: Users.totalItems,
                                    page: Users.page,
                                    limit: Users.limit,
                                    styleTd: [{ width: 100 }, { width: 100 }],
                                }}
                                onLoad={loadUsers}
                                footers={
                                    <Link
                                        to="/user/create"
                                        className="btn btn-primary"
                                    >
                                        Add
                                    </Link>
                                }
                            />
                        </div>
                    </div>
                </div>
                <div
                    className={`modal fade ${modal.isVisible ? "show" : ""}`}
                    id="modal-default"
                    aria-modal="true"
                    style={{
                        display: modal.isVisible ? "block" : "none",
                        backgroundColor: "rgba(0,0,0,0.5)",
                    }}
                    role="dialog"
                >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">{modal.title}</h4>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span
                                        aria-hidden="true"
                                        onClick={() =>
                                            setModal({
                                                ...modal,
                                                isVisible: false,
                                            })
                                        }
                                    >
                                        ×
                                    </span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td style={{ width: 150 }}>ID</td>
                                            <td>: {modal.id}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ width: 150 }}>
                                                Email
                                            </td>
                                            <td>: {modal.email}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="modal-footer justify-content-between">
                                <button
                                    type="button"
                                    className="btn btn-default"
                                    data-dismiss="modal"
                                    onClick={() =>
                                        setModal({
                                            ...modal,
                                            isVisible: false,
                                        })
                                    }
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => {
                                        submitModal(modal.id);
                                    }}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};
export default UsersList;
