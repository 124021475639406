import { all, fork } from "redux-saga/effects";
import SigninSaga from "./auth/signin/saga";
import TicketSaga from "./ticket/saga";
import TicketCategorySaga from "./ticket_category/saga";
import DepartmentSaga from "./department/saga";
import UsersSaga from "./users/saga";

export default function* rootSaga() {
    yield all([
        //public
        fork(SigninSaga),
        fork(TicketSaga),
        fork(TicketCategorySaga),
        fork(DepartmentSaga),
        fork(UsersSaga),
    ]);
}
