import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import Signin from "./auth/signin/reducer";
import Ticket from "./ticket/reducer";
import TicketCategory from "./ticket_category/reducer";
import Department from "./department/reducer";
import Users from "./users/reducer";

const persistConfig = {
    key: "root",
    storage,
    whitelist: [],
};
const rootReducer = combineReducers({
    Signin,
    Ticket,
    TicketCategory,
    Department,
    Users,
});

export default persistReducer(persistConfig, rootReducer);
